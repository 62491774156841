import styled from 'styled-components';
import { motion } from 'framer-motion';
import { fullSize } from '@/styles/_mixins';
import { layout } from '@/styles/_theme';

export const Wrapper = styled(motion.div)`
  ${fullSize()}
  position:relative;
  height: 100vh;
  z-index: ${layout.zIndex.canvas};
  // pointer-events:  ${props => props.$pointerEvents ? 'auto' : 'none'} !important;
  // & > div:first-child {
  //   pointer-events: ${props => props.$pointerEvents ? 'auto' : 'none'} !important;
  // }
`;

export const InnerWrapper = styled(motion.div)`
${fullSize({ position : 'relative' })}
`;
