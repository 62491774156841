import {
  AphroditeModel, HerculesModel, MedusaModel, OrpheusModel,
  PanModel, PhoenixModel, SphinxModel, ThreeGracesModel, TriremeModel
} from '@/components/canvas/SculptureCarousel/SculpturesModels';

//import dynamic from 'next/dynamic';

// const AphroditeModel = dynamic( async () => await import('@/components/canvas/SculptureCarousel/SculpturesModels'));
// const HerculesModel = dynamic( async () => await import('@/components/canvas/SculptureCarousel/SculpturesModels'));
// const MedusaModel = dynamic( async () => await import('@/components/canvas/SculptureCarousel/SculpturesModels'));
// const OrpheusModel = dynamic( async () => await import('@/components/canvas/SculptureCarousel/SculpturesModels'));
// const PanModel = dynamic( async () => await import('@/components/canvas/SculptureCarousel/SculpturesModels'));
// const PhoenixModel = dynamic( async () => await import('@/components/canvas/SculptureCarousel/SculpturesModels'));
// const SphinxModel = dynamic( async () => await import('@/components/canvas/SculptureCarousel/SculpturesModels'));
// const ThreeGracesModel = dynamic( async () => await import('@/components/canvas/SculptureCarousel/SculpturesModels'));

const data =  [
  ['aphrodite', <AphroditeModel key={'aphrodite'} />],
  ['hercules', <HerculesModel key={'hercules'} />],
  ['medusa', <MedusaModel key={'medusa'} />],
  ['orpheus', <OrpheusModel key={'orpheus'} />],
  ['pan', <PanModel key={'pan'} />],
  ['phoenix', <PhoenixModel key={'phoenix'} />],
  ['sphinx', <SphinxModel key={'sphinx'} />],
  ['three-graces', <ThreeGracesModel key={'three-graces'} />],
  ['trireme', <TriremeModel key={'trireme'} />]
];

export const modelsComponents = new Map(data);
