
import React, {
  useEffect, useRef, useState, useCallback
} from 'react';
import { PerspectiveCamera } from '@react-three/drei';
import PropTypes from 'prop-types';
import gsap from 'gsap';
import CustomEase from 'gsap/dist/CustomEase';
import { useThree, useFrame } from '@react-three/fiber';
import { useRouter } from 'next/router';
import { usePrevious } from '@/hooks/use-previous';
import useSculpturesSliderStore, { sculpturesSliderStore } from '@/store/_sculptures-slider';
import { modelsComponents } from '@/components/ui/Carousel/Carousel.data';
import { useWindowSizeStore } from '@/store';
import { breakpointDesktop } from '@/styles/_responsive';

const propTypes = {
  route : PropTypes.object
};
const defaultProps = {
  route : {}
};

const TWO_PI = Math.PI * 2;

const SculptureCarousel = () => {

  const router = useRouter();

  const carouselOrigin = useRef();
  const sculptureGroups = useRef([]);
  const sculptureModels = useRef([]);
  const materials = useRef([]);
  const rotationVectors = useRef([]);

  const isSlug = !!router?.query.slug;
  const {
    sculpturesData,
    currentSlide
  } = useSculpturesSliderStore((state) => ({
    sculpturesData : state.sculpturesData,
    currentSlide   : state.currentSlide
  }));
  const windowSize = useWindowSizeStore(state => ({
    width  : state.width,
    height : state.height
  }));

  const previousSlide = usePrevious(currentSlide);

  const scene = useThree((state) => state.scene);

  const [rotatingIn, setRotatingIn] = useState(false);
  const mouseTargetRef = useRef(0);
  const mouseRef = useRef(0);
  useFrame(() => {
    const group = sculptureGroups.current[currentSlide];
    if (!rotatingIn) {
      if (group) {
      // on sculpture carousel
        if (router.pathname !== '/sculptures/[slug]') {
          mouseRef.current += (mouseTargetRef.current - mouseRef.current) * 0.01;
          group.rotation.set(group.rotation.x, mouseRef.current * 2, group.rotation.z);
          return;
        }
        // on sculpture details page
        group?.rotation.set(group.rotation.x, group.rotation.y + 0.002, group.rotation.z);
      }
    }
  });

  useEffect(() => {
    const group = sculptureGroups.current[currentSlide];
    if (group) {
      gsap.to(group?.rotation, {
        x : 0,
        y : 0,
        z : 0
      });
    }
  }, [currentSlide, router.pathname]);
  const windowSizeRef = useRef(windowSize);

  const modelsRef = useRef();

  const mouseMove = useCallback((e) => {
    const w = windowSizeRef.current.width / 2;
    const h = windowSizeRef.current.height / 2;

    const x = (e.clientX - w) / w;
    const y = (e.clientY - h) / h;

    if (router.pathname !== '/sculptures/[slug]') {
      mouseTargetRef.current += (x - mouseTargetRef.current) * 0.1;

      return;
    };

    modelsRef.current?.position.set(-x / 8, y / 8, modelsRef.current?.position.z);

  }, [router.pathname]);

  useEffect(() => {
    mouseRef.current = 0;
  }, [currentSlide]);
  useEffect(() => {
    windowSizeRef.current = windowSize;
  }, [windowSize]);

  useEffect(() => {
    document.addEventListener('mousemove', mouseMove);
    return () => {
      document.removeEventListener('mousemove', mouseMove);
    };
  }, [mouseMove]);

  useEffect(() => {
    // reset fog and background on mount.
    // TODO : reset bloom if eventually added too (because it will fuck with alpha around sculptures)

    scene.fog = null;
    scene.background = null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [detailsPosition, setDetailsPosition] = useState([-2, 0, 4]);

  useEffect(() => {
    if (windowSize.width < breakpointDesktop) {
      if (detailsPosition[0] !== 0)
        setDetailsPosition([0, 1, 0]);
    } else {
      if (detailsPosition[0] !== -2)
        setDetailsPosition([-2, 0, 4]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowSize]);

  const sculptures = sculpturesData.length > 0 && sculpturesData.map((model, i) => {

    const angle = sculpturesData.length > 0 && TWO_PI / sculpturesData.length;
    return (
      <group
        rotation={[0, -angle * i, 0]}
        key={i}
      >
        <group position={[0, -1, -8]} ref={(ref) => sculptureGroups.current[i] = ref}>
          <group
            ref={(ref) => sculptureModels.current[i] = ref}
            name={i}
            scale={2}
          >
            {modelsComponents.get(model.slug)}
          </group>
        </group>
      </group>
    );
  });

  gsap.registerPlugin(CustomEase);
  CustomEase.create('custom', 'M0,0 C0.758,0 0.264,1 1,1 ');

  useEffect(() => {
    if (sculptureGroups.current.length === 0 ) return;

    const timeline = gsap.timeline({ paused : true });
    const angle =  sculpturesData.length > 0 && TWO_PI / (sculpturesData.length);
    const arLength = sculptureModels.current.length > 0 && sculptureModels.current.length;

    // set up each model's opacity transitions on timeline
    sculptureModels.current.length > 0 && sculptureModels.current.forEach((model, i) => {

      if (model.children.length > 0) {
        const mat = model.children[0].children[0]?.material ?? model.children[0].children[0].children[0].material;
        mat.transparent = true;

        materials.current[i] = mat;

        //set models .visible param when not visible for performance

        timeline.fromTo(mat,
          { opacity : 0 },
          {
            opacity  : 1,
            duration : 1,
            ease     : 'linear',
            onUpdate : () => {
              model.visible = mat.opacity > 0.01;
            }

          }, i);

        timeline.fromTo(mat,
          { opacity : 1 },
          {
            opacity  : 0,
            duration : 1,
            ease     : 'linear',
            onUpdate : () => {
              model.visible = mat.opacity > 0.01;
            }

          }, i + 1);

        model.visible = false;
      }

      rotationVectors.current[i] = model.rotation;
    });

    // set all model materials to opacity 1 at beginning of timeline
    timeline.set(materials.current, { opacity : 0 }, 0);

    // animate the rotation of the entire carousel based on sculpture array length
    timeline.set(carouselOrigin.current.rotation, { y : -angle }, 0);
    for (let i = 0; i <= arLength; i++) {
      timeline.to(
        carouselOrigin.current.rotation,
        {
          y        : angle * i,
          duration : 1,
          ease     : 'linear'
        },
        i
      );
    }

    // if the user lands on a sculpture detail page, set the position of the group to the left
    // TODO: ignore at smaller breakpoints
    if (isSlug){
      carouselOrigin.current.position.set(detailsPosition[0], detailsPosition[1], detailsPosition[2]);
    }
    const activeSlide = sculpturesData.map(sculpture => sculpture.slug).indexOf(router?.query.slug);

    const slide = activeSlide !== -1 ? activeSlide : currentSlide;
    // set the initial timeline position if the user first lands on a sculpture detail page
    // otherwise set to the first sculpture
    timeline.progress((slide + 1) / (sculpturesData.length + 1));

    // subscribe the timeline progress to the store var slideTimelinePosition
    // that is controlled by the DOM component Carousel
    // use gsap so that the position is smoothed
    const unsubscribe = sculpturesSliderStore.subscribe(state =>
      [state.slideTimelinePosition, state.updateImmediately],
    (value) => {
      const [slideTimelinePosition, updateImmediately] = value;

      if (updateImmediately) {
        timeline.progress(slideTimelinePosition);
      } else {
        gsap.to(timeline, {
          progress  : slideTimelinePosition,
          duration  : 1.5,
          ease      : 'power2.out',
          overwrite : true,
          lazy      : false

        });
      }
    }
    );

    return () => {
      timeline.kill();
      unsubscribe();
    };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sculpturesData, detailsPosition]);

  useEffect(() => {
    const sculptureDetailsTimeline = gsap.timeline();
    let timeout = null;
    if (isSlug){
      gsap.to(carouselOrigin.current.position,
        {
          x : detailsPosition[0],
          y : detailsPosition[1],
          z : detailsPosition[2]
        });
    } else {
      gsap.to(carouselOrigin.current.position,
        {
          x : 0,
          y : 0,
          z : 0
        });
    }

    gsap.fromTo(rotationVectors.current[currentSlide],
      {
        x : 0,
        y : 0,
        z : 0
      },
      {
        x        : 0,
        y        : (-2 * Math.PI),
        z        : 0,
        duration : 2,
        ease     : 'power2.out',
        onStart  : () => {
          setRotatingIn(true);
        },
        onComplete : () => {
          if (rotationVectors.current[currentSlide])
            rotationVectors.current[currentSlide].y = 0;

          timeout = setTimeout(() => {
            setRotatingIn(false);
          }, 50);
        }
      });

    gsap.fromTo(rotationVectors.current[previousSlide],
      {
        x : 0,
        y : 0,
        z : 0
      },
      {
        x        : 0,
        y        : (-2 * Math.PI),
        z        : 0,
        // stagger  : { each : 1, yoyo : true, repeat : 1 },
        duration : 2,
        ease     : 'power2.out'
      });

    return () => {
      sculptureDetailsTimeline.kill();

      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
    };

  }, [isSlug, currentSlide, previousSlide, detailsPosition]);

  return (
    <>
      <PerspectiveCamera
        makeDefault
        far={100}
        near={0.1}
        fov={25}
        position={[0, .2, 8] }
        // rotation={[0, 0, 0]}
        onUpdate={(self) => self.updateProjectionMatrix()}
      />
      <ambientLight intensity={1.8} />
      <group ref={carouselOrigin}>
        {sculptures}
      </group>
    </>
  );
};
SculptureCarousel.propTypes = propTypes;
SculptureCarousel.defaultProps = defaultProps;

export default SculptureCarousel;
