import * as THREE from 'three';

export const pathPositions = [
  new THREE.Vector3( 10, 25, -580 ),
  new THREE.Vector3(-30, 12, -550 ),
  new THREE.Vector3(-40, 12, -490 ),
  new THREE.Vector3(-50, 12, -470),
  new THREE.Vector3(-40, 11, -450),
  new THREE.Vector3(-10, 11, -390),
  new THREE.Vector3(135, 10, -400),
  new THREE.Vector3(90, 12, -490),
  new THREE.Vector3(-60, 12, -450)
];

export const lookAtTarget = [
  new THREE.Vector3(40, 8, -435 ),
  new THREE.Vector3(20, 10, -430 ),
  new THREE.Vector3(60, 10, -420),
  new THREE.Vector3(50, 5, -490),
  new THREE.Vector3(10, 12, -390)];

export const amphiteathrePath = [
  new THREE.Vector3(0, 2, 60),
  new THREE.Vector3(0, 2, -2 )
];
