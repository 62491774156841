import * as THREE from 'three';
import { useFrame } from '@react-three/fiber';
//import { useVideoTexture } from '@react-three/drei';
import { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import {  useAppStore } from '@/store';
import useCanvasStore from '@/store/_canvas.js';

const propTypes = {
  introFinished      : PropTypes.bool,
  isEnteredAmphi     : PropTypes.bool,
  introAmphiFinished : PropTypes.bool,
  videoTexture       : PropTypes.object
};
const defaultProps = {
  introFinished      : '',
  isEnteredAmphi     : '',
  introAmphiFinished : '',
  videoTexture       : {}

};

const FogScreen = ({
  introFinished, isEnteredAmphi, introAmphiFinished, videoTexture
}) => {

  const texture = new THREE.VideoTexture( videoTexture );

  const {
    sceneName
  }
    = useCanvasStore((state) =>
      ({
        sceneName : state.sceneName
      }));

  const {
    isUserEntered
  } = useAppStore(state => ({
    isUserEntered : state.isUserEntered
  }));

  const fog = useRef();
  const fogScreen = useRef();

  const isIntroIn = isUserEntered && !introFinished;
  const isAmphitheatreIn = sceneName === 'amphi-zoom-out' && !isEnteredAmphi && !introAmphiFinished;

  useEffect(() => {
    fog.current.blending = THREE.AdditiveBlending;
    fog.current.depthWrite = false;

  }, []);

  useFrame(() => {
    fogScreen.current.material.opacity =
    THREE.MathUtils.lerp(fogScreen.current.material.opacity, isIntroIn || isAmphitheatreIn ? 1 : 0, 0.025);
  });

  return (
    <mesh
      ref={fogScreen}
      position={[0, 0, -1.6]}
      rotation={[0, 0, Math.PI * 0.5]}
    >
      <planeBufferGeometry args={[2, 3.5, 1, 1]} />
      <meshBasicMaterial
        alphaMap={texture}
        ref={fog}
        side={THREE.DoubleSide}
        transparent
      />
    </mesh>
  );
};

FogScreen.propTypes = propTypes;
FogScreen.defaultProps = defaultProps;

export default FogScreen;
