import React, { useEffect, useRef } from 'react';
import { addAfterEffect, addEffect } from '@react-three/fiber';
import usePerformanceStore from '@/store/_performance.js';

export default function PerformanceMonitor() {
  const beginTime = useRef(0);
  const prevTime = useRef(0);
  const frames = useRef(0);
  const fps = useRef(0);
  const slowFpsCounter = useRef(0);
  const _performanceTier = useRef(2);

  const [stepDownTier, performanceTier] = usePerformanceStore(state => [state.stepDownTier, state.performanceTier]);

  useEffect(() => {
    _performanceTier.current = performanceTier;

    const begin = addEffect(() => {
      beginTime.current = ( performance || Date ).now();
    });
    const end = addAfterEffect(() => {
      if (_performanceTier.current <= 1) return;

      frames.current++;

      const time = ( performance || Date ).now();

      if ( time > prevTime.current + 1000 ) {
        fps.current = ( frames.current * 1000 ) / ( time - prevTime.current );

        // if FPS is less that 55 for more than 3 samples. Step down tier and reset
        if (fps.current < 55) {
          slowFpsCounter.current++;
          if (slowFpsCounter.current > 3) {
            stepDownTier();
            _performanceTier.current--;
            slowFpsCounter.current = 0;
          }
        } else {
          slowFpsCounter.current = 0;
        }

        prevTime.current = time;
        frames.current = 0;
      }
    });

    return () => {
      begin();
      end();
    };
  }, []);

  return <></>;
}
