import React, {
  useRef, useEffect, Suspense, useState
} from 'react';
import { RouterContext } from 'next/dist/shared/lib/router-context';
import { Canvas, useFrame, useThree } from '@react-three/fiber';
import PropTypes from 'prop-types';
import { ScrollControls, useContextBridge, Html } from '@react-three/drei';
import gsap from 'gsap';
import { useInView } from 'framer-motion';
import { useRouter } from 'next/router.js';
import SculptureCarousel from '@/components/canvas/SculptureCarousel/SculptureCarousel.jsx';
import { sculpturesSliderStore } from '@/store/_sculptures-slider';
import ThreeScene from '@/components/canvas/ThreeScene/ThreeScene.jsx';
import useCanvasStore from '@/store/_canvas.js';
import { TriremeModel } from '@/components/canvas/SculptureCarousel/SculpturesModels.jsx';
import PerformanceMonitor from '@/components/canvas/ThreeCanvas/ThreeCanvas.performance.jsx';
import usePerformanceStore from '@/store/_performance.js';
import * as Styled from './ThreeCanvas.styled.js';

const propTypes = {
  route  : PropTypes.object,
  router : PropTypes.object
};
const defaultProps = {
  route  : {},
  router : {}

};

const ThreeCanvas = () => {

  const ContextBridge = useContextBridge(RouterContext);
  const router = useRouter();
  const {
    sceneName
  }
    = useCanvasStore((state) =>
      ({
        sceneName : state.sceneName
      }));

  const performanceTier = usePerformanceStore(state => state.performanceTier);

  const isTrireme = router.pathname === '/trireme';
  const isScene = sceneName === 'amphitheatre' || sceneName === 'intro' || sceneName === 'intro-text' || sceneName === 'amphi-zoom-out';
  const isAmphiScene = sceneName === 'amphi-zoom-out' || sceneName === 'amphitheatre';
  const isCarousel = sceneName === 'carousel' || sceneName === 'sculpture-details' || router.pathname === '/trireme';

  const ref = useRef();
  const videoTexture = useRef();

  const isInView = useInView(ref);
  const [pointerEvents, setPointerEvents] = useState(false);
  const [dprValue, setDprValue] = useState([1, 2]);

  const performanceDPRValues = {
    highRes : [1, 2],
    lowRes  : [0.5, 1]
  };

  useEffect(() => {
    const canvas = document.getElementById('canvas');
    if (router.pathname !== '/sculptures/[slug]' ) {
      setPointerEvents(true);
      ref.current.style.pointerEvents = 'auto';
      canvas.style.pointerEvents = 'auto';
      // canvas.children[0].style.pointerEvents = 'auto';
    } else {
      setPointerEvents(false);
      ref.current.style.pointerEvents = 'none';
      canvas.style.pointerEvents = 'none';
      // canvas.children[0].style.pointerEvents = 'none';
    }

  }, [router.pathname]);

  useEffect(() => {
    performanceTier < 2 ? setDprValue(performanceDPRValues.lowRes) : setDprValue(performanceDPRValues.highRes);
  }, [performanceTier]);

  return (
    <Styled.Wrapper
      ref={ref}
      $pointerEvents={pointerEvents}
    >
      <video
        style={{ position : 'absolute', zIndex : '-5' }}
        ref={videoTexture}
        crossOrigin="anonymous"
        id={'video'}
        muted={true}
        autoPlay
        preload="none"
        loop
        width={1}
        height={1}
      >
        <source src={'/textures/fog.mp4'} />
      </video>
      <Canvas
        id="canvas"
        frameloop={isInView ? 'always' : 'demand'}
        dpr={dprValue}
      >
        <PerformanceMonitor />
        <ContextBridge>
          <Suspense fallback={null}>
            {isScene && (
              <ScrollControls pages={isAmphiScene ? 3 : 10}>
                <ThreeScene videoTexture={videoTexture.current && videoTexture.current}  />
              </ScrollControls>
            )}
          </Suspense>
          {isCarousel && !isTrireme && <SculptureCarousel  /> }
          {isTrireme && <TriremeModel />}
        </ContextBridge>

      </Canvas>
    </Styled.Wrapper>
  );
};

ThreeCanvas.propTypes = propTypes;
ThreeCanvas.defaultProps = defaultProps;

export default ThreeCanvas;
