import React, {
  useEffect,
  useRef,
  useMemo,
  useState,
  useCallback
} from 'react';
import PropTypes from 'prop-types';
import {
  useScroll,
  PerspectiveCamera,
  Environment,
  GradientTexture
} from '@react-three/drei';
import { useFrame, useThree } from '@react-three/fiber';
import { useRouter } from 'next/router';
import * as THREE from 'three';
import gsap from 'gsap';
import useCanvasStore from '@/store/_canvas.js';
import { useAppStore, useWindowSizeStore } from '@/store';
import {
  pathPositions,
  lookAtTarget,
  amphiteathrePath
} from '@/components/canvas/ThreeScene/ThreeScene.data';
import {
  AmphitheatreModel,
  BoatAndBackgroundModel
} from '@/components/canvas/ThreeScene/ThreeScene.models';
import FogScreen from '@/components/canvas/Fog/Fog';

const propTypes = {
  videoTexture : PropTypes.object
};
const defaultProps = {
  videoTexture : {}
};
const cameraFOVControl = (windowSize, sceneName) => {
  let fov;
  const isHome = sceneName === 'intro' || sceneName === 'intro-text';
  const isAmphi =
    sceneName === 'amphi-zoom-out' || sceneName === 'amphitheatre';
  if (isHome && !isAmphi) {
    if (windowSize > 1024) fov = 25;
    if (windowSize < 1024) fov = 45;
  } else {
    if (windowSize > 1024) fov = 45;
    if (windowSize < 1024) fov = 75;
  }

  return fov;
};

// let move = 0;

const SPEED_DESKTOP = 0.01;
const SPEED_MOBILE = 0.035;
const RANGE_DESKTOP = 0.4;
const RANGE_MOBILE = 0.8;

const phiVector = new THREE.Vector3();
const thetaVector = new THREE.Vector3();

const ThreeScene = ({ videoTexture }) => {
  const router = useRouter();

  const isAmphiSlug = router.route === '/amphitheatre';
  const isHomeSlug = router.route === '/';

  const { sceneName, setSceneName } = useCanvasStore((state) => ({
    sceneName    : state.sceneName,
    setSceneName : state.setSceneName
  }));

  const {
    isUserEntered,
    setUserStartInteractive,
    scrolling,
    setScrolling,
    setIsUserLooking,
    isUserLooking
  } = useAppStore((state) => ({
    isUserEntered           : state.isUserEntered,
    setUserStartInteractive : state.setUserStartInteractive,
    scrolling               : state.scrolling,
    setScrolling            : state.setScrolling,
    setIsUserLooking        : state.setIsUserLooking,
    isUserLooking           : state.isUserLooking
  }));

  //const performanceTier = usePerformanceStore(state => state.performanceTier);

  const windowWidth = useWindowSizeStore((state) => state.width);

  const scroll = useScroll();

  const touchPointer = useRef(new THREE.Vector2());
  const qx = useRef(new THREE.Quaternion());
  const qz = useRef(new THREE.Quaternion());
  const q = useRef(new THREE.Quaternion());

  const xh = useRef();
  const yh = useRef();
  const phi = useRef(0);
  const theta = useRef(0);

  const tubeCameraPath = useRef();
  const tubeTargetPath = useRef();
  const tubeEntrancePath = useRef();
  const screen = useRef();
  const cameraRef = useRef();

  const entrancePath = useRef(new THREE.Vector3());

  const [isIntroScene, setIsIntroScene] = useState(false);
  const [isAmphiScene, setIsAmphiScene] = useState(false);
  const [isEnteredAmphi, setIsEnteredAmphi] = useState(false);
  const [introFinished, setIntroFinished] = useState(false);
  const [introAmphiFinished, setIntroAmphiFinished] = useState(false);

  const curveTarget = useMemo(
    () => new THREE.CatmullRomCurve3([...lookAtTarget], true, 'centripetal'),
    [lookAtTarget]
  ); // eslint-disable-line no-eval
  const curvePath = useMemo(
    () => new THREE.CatmullRomCurve3([...pathPositions], true, 'centripetal'),
    [pathPositions]
  ); // eslint-disable-line no-eval
  const amphiEntrance = useMemo(
    () =>
      new THREE.CatmullRomCurve3([...amphiteathrePath], false, 'centripetal'),
    [amphiteathrePath]
  ); // eslint-disable-line no-eval

  const clamp = (num, min, max) => Math.min(Math.max(num, min), max);

  const { camera } = useThree();

  useEffect(() => {
    if (isAmphiSlug && sceneName !== 'amphi-zoom-out')
      setSceneName('amphi-zoom-out');
    if (isUserEntered && isHomeSlug && sceneName !== 'intro-text')
      setSceneName('intro');
  }, []);

  useEffect(() => {
    const handleDrag = (e) => {
      if (!isUserLooking) setIsUserLooking(true);
      touchPointer.current.x =
        (e.targetTouches[0].clientX / window.innerWidth) * 2 - 1;
      touchPointer.current.y =
        -(e.targetTouches[0].clientY / window.innerHeight) * 2 + 1;
    };
    const handleDragReset = () => {
      touchPointer.current.x = 0;
      touchPointer.current.y = 0;
    };

    if (document !== undefined && isEnteredAmphi) {
      document.addEventListener('touchmove', handleDrag);
      document.addEventListener('touchend', handleDragReset);
    }

    return () => {
      document.removeEventListener('touchmove', handleDrag);
      document.removeEventListener('touchend', handleDragReset);
    };
  }, [isEnteredAmphi]);

  useEffect(() => {
    const handleMove = () => {
      // setUserStartInteractive(true);
    };

    if (document !== undefined && isEnteredAmphi) {
      document.addEventListener('mousemove', handleMove);
    }
    return () => {
      document.removeEventListener('mousemove', handleMove);
    };
  }, [isEnteredAmphi]);

  useEffect(() => {
    scroll.el.id = 'scroller3D';

    setIsAmphiScene(
      sceneName === 'amphi-zoom-out' || sceneName === 'amphitheatre'
    );
    setIsIntroScene(sceneName === 'intro' || sceneName === 'intro-text');

    const timeline = gsap.timeline();

    //Intro start - move into position to start scrolling
    if (isUserEntered && screen.current && !introFinished) {
      scroll.el.scrollTop = 0;
      scroll.offset = 0;

      camera.position.set(60, 40, -630);
      timeline.to(
        camera.position,
        {
          x          : 10,
          y          : 25,
          z          : -580,
          duration   : 2,
          ease       : 'power2.out',
          onStart    : () => (scroll.el.style.overflow = 'hidden'),
          onComplete : () => (scroll.el.style.overflow = 'auto')
        },
        0
      );

      timeline.fromTo(
        screen.current.material,
        { opacity : 1 },
        {
          opacity    : 0,
          duration   : 2,
          ease       : 'power2.out',
          onComplete : () => setIntroFinished(true)
        },
        0
      );
    }

    //Outside Amphitheatre start - move into position to start scrolling
    if (
      sceneName === 'amphi-zoom-out' &&
      screen.current &&
      !isEnteredAmphi &&
      !introAmphiFinished
    ) {
      scroll.el.scrollTop = 0;
      scroll.offset = 0;
      cameraRef.current.position.x = 0;

      timeline.fromTo(
        screen.current.material,
        { opacity : 1 },
        {
          opacity  : 0,
          duration : 2,
          ease     : 'power2.out'
        },
        0
      );

      timeline.fromTo(
        camera.position,
        { z : 90, y : 15, x : 0 },
        {
          z : 60,
          y : 2,
          x : 0,

          duration : 2,
          ease     : 'power2.out',
          onStart  : () => {
            scroll.el.style.overflow = 'hidden';
          },
          onComplete : () => {
            scroll.el.style.overflow = 'auto';
            setIntroAmphiFinished(true);
          }
        },

        0
      );
    }

    //Inside Amphitheatre - move into position lock in camera
    if (sceneName === 'amphitheatre' && isEnteredAmphi) {
      gsap.to(camera.position, { z : -2, duration : 2, ease : 'linear' });

      camera.rotation.set(0, 0, 0);
    }

    return () => {
      timeline.kill();
    };
  }, [
    sceneName,
    isEnteredAmphi,
    isUserEntered,
    introFinished,
    introAmphiFinished,
    scroll.el,
    camera.position,
    camera.rotation
  ]);

  useEffect(() => {
    const handleRouteChange = (url, { shallow }) => {
      if (router.route === '/amphitheatre' && url !== '/amphitheatre') {
        gsap.fromTo(
          screen.current.material,
          { opacity : 0 },
          {
            opacity  : 1,
            duration : 1,
            ease     : 'linear'
          },
          0
        );
      }
    };

    router.events.on('routeChangeStart', handleRouteChange);
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, []);

  useFrame(({ camera, pointer }, delta) => {
    if (sceneName === 'intro' && !introFinished) {
      camera.add(screen?.current);
      camera.lookAt(40, 8, -435);
    }

    if (sceneName === 'amphi-zoom-out' && !introAmphiFinished) {
      cameraRef.current.add(screen?.current);
      cameraRef.current.position.x = 0;
      cameraRef.current.lookAt(0, 2, -2);
    }

    if (
      introFinished &&
      sceneName !== 'amphi-zoom-out' &&
      tubeCameraPath.current &&
      tubeTargetPath.current
    ) {
      // move += 0.018 * delta;
      // move = THREE.MathUtils.clamp(move, 0.1, 1);
      // console.log({ delta });

      const positionPath =
        tubeCameraPath.current.geometry.parameters.path.getPointAt(
          scroll.offset
        );
      camera.position.set(positionPath.x, positionPath.y, positionPath.z);
      const targetPath =
        tubeTargetPath.current.geometry.parameters.path.getPointAt(
          scroll.offset
        );
      camera.lookAt(targetPath.x, targetPath.y, targetPath.z);

      const rangeText = scroll.visible(2 / 10, 2 / 10);

      rangeText ? setSceneName('intro-text') : setSceneName('intro');

      const range = scroll.range(3.6 / 10, 3.4 / 10);
      screen.current.material.opacity = range;

      if (range === 1) {
        setSceneName('amphi-zoom-out');
        //  if (router.route !== '/amphitheatre' )router.push('/amphitheatre');
      }
    }

    if (
      sceneName === 'amphi-zoom-out' &&
      tubeEntrancePath.current &&
      introAmphiFinished &&
      entrancePath.current &&
      cameraRef.current
    ) {
      entrancePath.current =
        tubeEntrancePath.current.geometry.parameters.path.getPointAt(
          Math.abs(scroll.offset)
        );
      cameraRef.current.position.set(
        entrancePath.current.x,
        entrancePath.current.y,
        entrancePath.current.z
      );
      cameraRef.current.lookAt(0, 2, -5);

      if (scroll.visible(8 / 10, 10 / 10)) {
        setSceneName('amphitheatre');
        if (!isEnteredAmphi) setIsEnteredAmphi(true);
      } else {
        //setSceneName('amphi-zoom-out')
        if (isEnteredAmphi) setIsEnteredAmphi(false);
      }
    }

    if (sceneName === 'amphitheatre' && isEnteredAmphi) {
      q.current.set(0, 0, 0, 1);

      let SPEED;
      let RANGE;
      if (windowWidth <= 1024) {
        SPEED = SPEED_MOBILE;
        RANGE = RANGE_MOBILE;
        xh.current = touchPointer.current.x;
        yh.current = -touchPointer.current.y;
      } else {
        SPEED = SPEED_DESKTOP;
        RANGE = RANGE_DESKTOP;
        xh.current = pointer.x;
        yh.current = -pointer.y;
      }
      xh.current *= THREE.MathUtils.smoothstep(Math.abs(xh.current), 0.05, 1);
      yh.current *= THREE.MathUtils.smoothstep(Math.abs(yh.current), 0.05, 1);

      phi.current = clamp(phi.current + -xh.current * SPEED, -RANGE, RANGE);
      theta.current = clamp(
        theta.current + -yh.current * (SPEED + 0.05),
        -Math.PI / 3,
        Math.PI / 3
      );

      qx.current.setFromAxisAngle(phiVector.set(0, 1, 0), phi.current);
      qz.current.setFromAxisAngle(
        thetaVector.set(1, 0, 0),
        theta.current * 0.3
      );

      q.current.multiply(qx.current);
      q.current.multiply(qz.current);

      camera.quaternion.copy(q.current);
    }
  });

  return (
    <>
      {/* {isAmphiScene && ( */}
      <Environment
        background={true}
        near={1}
        far={1000}
        resolution={256}
      >
        <mesh scale={10}>
          <sphereGeometry args={[1, 64, 64]} />
          <meshBasicMaterial side={THREE.BackSide}>
            <GradientTexture
              //stops={[0.5, 0.4]} // As many stops as you want
              //colors={  ['#FFFFFF', '#B3B3B3'] } // Colors need to match the number of stops
              stops={[0.5, 0.4]}
              colors={['#d1d1d1', '#787878']}
              size={256} // Size is optional, default = 1024
            />
          </meshBasicMaterial>
        </mesh>
      </Environment>
      {/* )} */}

      {isIntroScene && <color attach="background" args={['#F5F5F5']} />}

      <PerspectiveCamera
        makeDefault
        far={20000}
        near={0.1}
        fov={cameraFOVControl(windowWidth, sceneName)}
        //onUpdate={(self) => self.updateProjectionMatrix()}
        ref={cameraRef}
      >
        <FogScreen
          introFinished={introFinished}
          isEnteredAmphi={isEnteredAmphi}
          introAmphiFinished={introAmphiFinished}
          videoTexture={videoTexture}
        />
      </PerspectiveCamera>

      <directionalLight
        intensity={isIntroScene ? 1 : 3}
        position={isIntroScene ? [-200, 100, 10] : [-200, 80, -150]}
      />

      <ambientLight intensity={isIntroScene ? 0.1 : 0.1} />

      <mesh ref={screen} position={[0, 0, -1.5]}>
        <planeBufferGeometry args={[10, 10, 1, 1]} />
        <meshBasicMaterial
          color={'white'}
          transparent
          side={THREE.DoubleSide}
        />
      </mesh>

      {isIntroScene && (
        <group>
          <mesh ref={tubeCameraPath}>
            <tubeGeometry args={[curvePath, 1, 0.01, 1, false]} />
            <meshStandardMaterial transparent opacity={0} />
          </mesh>

          <mesh ref={tubeTargetPath}>
            <tubeGeometry args={[curveTarget, 1, 0.01, 1, false]} />
            <meshStandardMaterial transparent opacity={0} />
          </mesh>
          <BoatAndBackgroundModel />
        </group>
      )}

      {isAmphiScene && (
        <group>
          <mesh ref={tubeEntrancePath}>
            <tubeGeometry args={[amphiEntrance, 1, 0.01, 1, false]} />
            <meshStandardMaterial transparent opacity={0} />
          </mesh>
          <AmphitheatreModel />
        </group>
      )}
    </>
  );
};
ThreeScene.propTypes = propTypes;
ThreeScene.defaultProps = defaultProps;

export default ThreeScene;
