import React from 'react';
import { useGLTF, useAnimations  } from '@react-three/drei';

/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/
export const TriremeModel = (props) => {
  const gltf  = useGLTF('/models/Ship_animated-transformed.glb');
  return (
    <>
      <ambientLight intensity={0.2} />
      <group rotation={[0, 0, 0]} key={99999}>
        <group position={[0, -3.3, -8]}>
          <group
            scale={5}
          >
            <group
              {...props}
              dispose={null}
              scale={0.006}
              rotation={[0.4, 1.1, 0]}
              position={[-0.1, 0.4, 0]}
            >
              <primitive object={gltf.scene} />
            </group>
          </group>
        </group>
      </group>
    </>
  );
};

// useGLTF.preload('/models/Ship_animated.glb');

export const AphroditeModel = (props) => {
  const { nodes, materials } = useGLTF('/models/Aphrodite_50k-transformed.glb');
  // console.info(nodes, materials);
  return (
    <group
      {...props}
      dispose={null}
    >
      <mesh
        geometry={nodes.Aphrodite_50k.geometry}
        material={materials.Aphrodite_50k}
        scale={0.9}

      />
    </group>
  );
};

// useGLTF.preload('/models/Aphrodite_50k.glb');

export const HerculesModel = (props) => {
  const { nodes, materials } = useGLTF('/models/Hercules_50k-transformed.glb');
  return (

    <group
      {...props}
      dispose={null}
    >
      <mesh
        scale={1.2}

        geometry={nodes.Hercules_50k.geometry}
        material={materials.Hercules_50k}
      />
    </group>
  );
};

// useGLTF.preload('/models/Hercules_50k.glb');

export const  MedusaModel = (props) => {
  const { nodes, materials } = useGLTF('/models/Medusa_50k-transformed.glb');
  return (
    <group {...props} dispose={null}>
      <mesh

        geometry={nodes.Medusa_50k.geometry}
        material={materials.Medusa_50k}
      />
    </group>
  );
};

// useGLTF.preload('/models/Medusa_50k.glb');

export const OrpheusModel = (props) => {
  const { nodes, materials } = useGLTF('/models/Orpheus_50k-transformed.glb');
  return (
    <group
      {...props}
      dispose={null}

    >
      <mesh
        rotation={[0, 1, 0]}
        scale={0.9}
        geometry={nodes.Orpheus_50k.geometry}
        material={materials.Orpheus_50k}
      />
    </group>
  );
};

// useGLTF.preload('/models/Orpheus_50k.glb');

export const PanModel = (props) => {
  const { nodes, materials } = useGLTF('/models/Pan_50k-transformed.glb');
  return (
    <group
      {...props}
      dispose={null}
      position={[0, 0.2, 0]}
    >
      <mesh
        scale={0.9}
        geometry={nodes.Pan_50k.geometry}
        material={materials.Pan_50k}
      />
    </group>
  );
};

// useGLTF.preload('/models/Pan_50k.glb');

export const PhoenixModel = (props) => {
  const { nodes, materials } = useGLTF('/models/Phoenix_50k-transformed.glb');
  return (
    <group
      {...props}
      dispose={null}

    >
      <mesh
        geometry={nodes.Pheonix_50k.geometry}
        material={materials.Pheonix_50k}
        scale={1.2}
        rotation={[0, 0, -0.14]}
      />
    </group>
  );
};

// useGLTF.preload('/models/Phoenix_50k.glb');

export const SphinxModel = (props) => {
  const { nodes, materials } = useGLTF('/models/Sphinx_50k-transformed.glb');
  return (
    <group
      {...props}
      dispose={null}
    >
      <group
        position={[0.07, 0, 0]}
      >
        <mesh
          geometry={nodes.Sphinx_50k.geometry}
          material={materials.Sphinx_50k}
        />
      </group>
    </group>
  );
};

// useGLTF.preload('/models/Sphinx_50k.glb');

export const ThreeGracesModel = (props) => {
  const { nodes, materials } = useGLTF('/models/Three_Graces_50k-transformed.glb');
  return (
    <group {...props} dispose={null}>
      <mesh

        geometry={nodes.Three_Graces_50k.geometry}
        material={materials.Three_Graces_50k}
      />
    </group>
  );
};

// useGLTF.preload('/models/Three_Graces_50k.glb');
